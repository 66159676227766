import { gsap, power2 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

document.addEventListener('DOMContentLoaded',function() {

    gsap.registerPlugin(ScrollTrigger);

    window.anims = function() {

        gsap.utils.toArray(".js-fadeIn").forEach(function(section) {
            gsap.from(section, {
                scrollTrigger: {
                    trigger: section,
                    start: '-50px bottom',
                    end: 'center 70%',
                    scrub: true,
                    toggleActions: "play reverse play reverse"
                },
                opacity: 0,
                duration: 1,
                y: 50,
            });
        });    
    }

}, false)
