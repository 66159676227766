(function() {
    const WebFont = require('webfontloader');

    WebFont.load({
        google: {
            families: ['Oswald:400,700:latin-ext', 'Roboto:300,400,600:latin-ext']
        }
    });
    
    
})();
