import css from './sass/style.scss';

require('./js/gsap.js');
require('./js/polyfills.js');
require('./js/init.js');
require('./js/fonts.js');
require('./js/ismobile.js');
require('./js/scrollpos.js');

require('./img/osmosis/osmosistop.jpg');
require('./img/osmosis/osmosistop@2x.jpg');