document.addEventListener('DOMContentLoaded',function() {

    let cover = document.getElementById('cover');
    
    const init = function() {
        document.documentElement.removeAttribute('style');
        document.documentElement.classList.add('is-loaded');
        
        setTimeout(function() {
            cover.remove();
            window.anims();
        }, 250);
    };
    
    window.addEventListener('load', init);


}, false);
